










































































import Vue from "vue";
import { ValidationObserver } from "vee-validate";
import { HealthCareInterests } from "@/providers/HealthCareInterests";
import AppLoading from "@/components/layout/AppLoading.vue";
import {
  ApiException,
  UserAccountUpdateInterestsCommand,
} from "@/clients/clients";
import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
  name: "SettingsUserInterestsView",
  components: { AppLoading, ValidationObserver },
  data: () => ({
    errorMessage: "",
    updated: false,
    loading: false,
    form: new UserAccountUpdateInterestsCommand(),
  }),
  computed: {
    ...mapGetters("UserStore", ["user"]),
    interests(): string[] {
      return HealthCareInterests;
    },
  },
  created() {
    this.form = Object.assign({}, this.form, {
      topicsOfInterests: [...this.user.interests],
    });
  },
  methods: {
    ...mapActions("UserStore", ["updateInterests"]),
    async onSubmit() {
      this.loading = true;
      this.errorMessage = "";
      try {
        await this.updateInterests(this.form);
        this.updated = true;
      } catch (e) {
        this.errorMessage = (e as ApiException).message;
      } finally {
        this.loading = false;
      }
    },
  },
});
